const FILTERS_LEADS_TAB = [
  { label: "Total Valid Leads", count: undefined },
  { label: "Active Leads", count: undefined },
  { label: "Duplicate Leads", count: undefined },
];

const FILTER_TABS_KEYS = {
  TOTAL_LEADS: "Total Valid Leads",
  ACTIVE_LEADS: "Active Leads",
  DUPLICATE_LEADS: "Duplicate Leads",
};

export { FILTERS_LEADS_TAB, FILTER_TABS_KEYS };
