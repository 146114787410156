import { LeadAllocatorDetailsContextType } from "./interface";
import actions from "./actions";

function allocatorDetailsPageReducer(
  state: LeadAllocatorDetailsContextType,
  action: { type: string; payload: any }
) {
  const {
    toggleOpenFilterModalAction,
    toggleCloseFilterModalAction,
    fetchSavedFilterDataSuccess,
    filterLeadActionDataAvailable,
    postFilterLeads,
    onExitModal,
  } = actions;
  const {
    filterModalDetails: { isFilterModalOpen },
  } = state;
  switch (action.type) {
    case toggleOpenFilterModalAction:
      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          isFilterModalOpen: !isFilterModalOpen,
          isFilterModalLoading: true,
        },
      };
    case toggleCloseFilterModalAction:
      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          isFilterModalOpen: false,
          isFilterModalLoading: false,
        },
      };
    case fetchSavedFilterDataSuccess:
      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          filterModalData: {
            savedFilters: action.payload,
          },
        },
      };
    case filterLeadActionDataAvailable:
      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          isFilterModalLoading: false,
        },
      };
    case postFilterLeads?.postFilterLeadsDataStart: {
      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          isFilterLeadsLoading: true,
          isStartFetchingFilterLeads: false,
        },
      };
    }
    case postFilterLeads?.postFilterLeadsDataLogId: {
      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          filterLogId: action.payload?.leadLogId,
          isStartFetchingFilterLeads: true,
        },
      };
    }
    case postFilterLeads?.postFilterLeadsData: {
      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          filteredLeadsData: action.payload,
        },
      };
    }

    case postFilterLeads?.postFilterLeadsDataError: {
      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          isFilterLeadsLoading: false,
          isFilterLeadsError: true,
          isFilterLeadsSuccess: false,
        },
      };
    }

    case postFilterLeads?.postFilterLeadsDataInitialize: {
      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          isFilterLeadsLoading: false,
          isFilterLeadsError: false,
          isFilterLeadsSuccess: false,
        },
      };
    }

    case postFilterLeads?.postFilterLeadsDataSuccess: {
      if (
        state?.filterModalDetails?.isFilterLeadsLoading ||
        action?.payload?.skipCheck
      ) {
        return {
          ...state,
          filterModalDetails: {
            ...state.filterModalDetails,
            ...(action.payload?.skipCheck
              ? {
                  filterLogId: action.payload?.leadLogId,
                }
              : {}),
            isFilterLeadsLoading: false,
            isFilterLeadsSuccess: true,
            isFilterLeadsError: false,
          },
        };
      }

      return {
        ...state,
        filterModalDetails: {
          ...state.filterModalDetails,
          isFilterLeadsLoading: false,
          isFilterLeadsError: false,
          isFilterLeadsSuccess: false,
        },
      };
    }

    case onExitModal.onExitFilterModal: {
      return {
        ...state,
        exitModalDetails: {
          isModalOpen: action?.payload?.isModalOpen,
          modalPosition: action?.payload?.modalPosition,
          modalTitle: action?.payload?.modalTitle,
          modalDescription: action?.payload?.modalDescription,
          modalCancelButtonAvailable:
            action?.payload?.modalCancelButtonAvailable,
          modalOkButtonAvailable: action?.payload?.modalOkButtonAvailable,
          modalOkButtonText: action?.payload?.modalOkButtonText,
          modalCancelButtonText: action?.payload?.modalCancelButtonText,
          onOk: action?.payload?.onOk,
          onCancel: action?.payload?.onCancel,
          modalImage: action?.payload?.modalImage,
          modalProps: action?.payload?.modalProps,
        },
      };
    }

    default:
      return state;
  }
}

export default allocatorDetailsPageReducer;
