import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// * contexts
import { NetworkStatusProvider } from "@contexts/NetworkStatusContext";
import { getUserPermissions } from "@contexts/PermissionContext";
import NotificationProvider from "@contexts/NotificationContext";
import SocketProvider from "@contexts/SocketContext";
import { LeadDetailsProvider } from "@contexts/LeadDetailsContext";

// * components
import PrivateRoutes from "@routes/PrivateRoutes";
import { getValueFromLocalStorage } from "@utils/local-storage";
import { fetchPermittedRoute } from "@utils/helper";
import { ToastProvider } from "@contexts/ToastContext";
import { LeadAllocatorProvider } from "@contexts/LeadAllocatorContext";
import { LeadAllocatorDetailsProvider } from "@contexts/FilterLeadsDetailsContext";

// * pages
const Application = lazy(() => import("@pages/Application"));
const Login = lazy(() => import("@pages/Login"));
const NotFound = lazy(() => import("@pages/NotFound"));

export default function App() {
  const userPermissions = getUserPermissions();
  return (
    <NetworkStatusProvider>
      <NotificationProvider>
        <ToastProvider>
          <LeadAllocatorProvider>
            <LeadAllocatorDetailsProvider>
              <SocketProvider>
                <LeadDetailsProvider>
                  <Router>
                    <Suspense>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            getValueFromLocalStorage("token") ? (
                              <PrivateRoutes>
                                <Navigate
                                  to={fetchPermittedRoute(userPermissions)}
                                />
                              </PrivateRoutes>
                            ) : (
                              <Navigate to="/login" />
                            )
                          }
                        />
                        <Route path="/login" element={<Login />} />
                        <Route
                          path={"/*"}
                          element={
                            <PrivateRoutes>
                              <Application />
                            </PrivateRoutes>
                          }
                        />
                        <Route path="/page-not-found" element={<NotFound />} />
                        <Route
                          path="*"
                          element={<Navigate to="/page-not-found" />}
                        />
                      </Routes>
                    </Suspense>
                  </Router>
                </LeadDetailsProvider>
              </SocketProvider>
            </LeadAllocatorDetailsProvider>
          </LeadAllocatorProvider>
        </ToastProvider>
      </NotificationProvider>
    </NetworkStatusProvider>
  );
}
