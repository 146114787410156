import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  ReactNode,
} from "react";
import ToastContainer from "../../components/Toast";

// Define the types for the toast context
interface ToastMessage {
  id: number;
  position:
    | "bottom-left"
    | "bottom-right"
    | "top-left"
    | "top-right"
    | "bottom-center";
  render: (id?: number) => ReactNode;
}

interface ToastContextType {
  addToast: (toast: Omit<ToastMessage, "id">) => void;
}

interface ToastProviderProps {
  children: ReactNode;
}

// Create the context
const ToastContext = createContext<ToastContextType | undefined>(undefined);

// Custom hook to use the toast context
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

// ToastProvider component
export function ToastProvider({ children }: ToastProviderProps) {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);

  const addToast = (toast: Omit<ToastMessage, "id">) => {
    const id = new Date().getTime(); // Generate a unique ID for the new toast
    setToasts((currentToasts) => [...currentToasts, { ...toast, id }]);

    // Set a timer to automatically remove this toast after 3 seconds
    setTimeout(() => {
      setToasts((currentToasts) => currentToasts.filter((t) => t.id !== id));
    }, 5000);
  };

  const value = useMemo(() => ({ addToast }), [toasts]);

  return (
    <ToastContext.Provider value={value}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
}
