import { LeadAllocatorDetailsContextType } from "@pages/Application/FilterAllocatorLeads/interface";
import allocatorDetailsPageReducer from "@pages/Application/FilterAllocatorLeads/reducer";
import React, { createContext, ReactNode, useMemo, useState } from "react";

const LeadAllocatorDetailsContext = createContext<
  LeadAllocatorDetailsContextType | any
>(undefined);

interface LeadAllocatorProviderProps {
  children: ReactNode;
}

function LeadAllocatorDetailsProvider({
  children,
}: LeadAllocatorProviderProps) {
  const [state, setState] = useState<LeadAllocatorDetailsContextType>({
    filterLeadsDetails: {},
    filterModalDetails: {
      isStartFetchingFilterLeads: false,
      isFilterModalOpen: false,
      filterModalData: {
        savedFilters: [],
      },
      isFilterLeadsError: false,
      isFilterLeadsLoading: false,
      isFilterLeadsSuccess: false,
      filterLogId: 0,
      csvUrl: "",
      totalFilteredLeads: 0,
      filteredLeadsData: {
        filterLogId: 0,
        csvUrl: "",
        totalFilteredLeads: 0,
      },
      isFilterModalLoading: false,
    },
  });

  async function dispatch(action: { type: string; payload: any }) {
    return new Promise((resolve) => {
      setState((prevState) => {
        const response = allocatorDetailsPageReducer(prevState, action);
        resolve(response);
        return response;
      });
    });
  }

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return (
    <LeadAllocatorDetailsContext.Provider value={contextValue}>
      {children}
    </LeadAllocatorDetailsContext.Provider>
  );
}

export { LeadAllocatorDetailsContext, LeadAllocatorDetailsProvider };
