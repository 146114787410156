import React from "react";

interface ToastContainerProps {
  toasts: ToastMessage[];
}

interface ToastMessage {
  id: number;
  position:
    | "bottom-left"
    | "bottom-right"
    | "top-left"
    | "top-right"
    | "bottom-center";
  render: (id?: number) => React.ReactNode;
}

function ToastContainer({ toasts }: ToastContainerProps) {
  // Group toasts by their position
  const groupedToasts = toasts.reduce((acc, toast) => {
    (acc[toast.position] = acc[toast.position] || []).push(toast);
    return acc;
  }, {} as Record<ToastMessage["position"], ToastMessage[]>);

  const getPositionClass = (position: ToastMessage["position"]) => {
    switch (position) {
      case "bottom-left":
        return "bottom-0 left-0";
      case "bottom-right":
        return "bottom-0 right-0";
      case "top-left":
        return "top-0 left-0";
      case "top-right":
        return "top-0 right-0";
      case "bottom-center":
        return "bottom-0 left-1/2 transform -translate-x-1/2";
      default:
        return "";
    }
  };

  return (
    <div>
      {Object.entries(groupedToasts).map(([position, allToasts]) => (
        <div
          key={position}
          className={`absolute z-[80] ${getPositionClass(
            position as ToastMessage["position"]
          )} p-4`}
        >
          {allToasts.map((toast) => (
            <div key={toast.id} className="my-2 relative animate-toast">
              {toast.render(toast.id)}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default ToastContainer;
