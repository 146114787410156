import { FILTERS_LEADS_TAB } from "@pages/Application/FilterAllocatorLeads/utils";
import React, {
  createContext,
  useMemo,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface LeadAllocatorContextType {
  currentLeadsTab: string;
  setCurrentLeadsTab: Dispatch<SetStateAction<string>>;
}

const LeadAllocatorContext = createContext<LeadAllocatorContextType | any>(
  undefined
);

interface LeadAllocatorProviderProps {
  children: ReactNode;
}

function LeadAllocatorProvider({ children }: LeadAllocatorProviderProps) {
  const [currentLeadsTab, setCurrentLeadsTab] = useState<string>(
    FILTERS_LEADS_TAB[0].label
  );

  const contextValue = useMemo(() => {
    return { currentLeadsTab, setCurrentLeadsTab };
  }, [currentLeadsTab]);

  return (
    <LeadAllocatorContext.Provider value={contextValue}>
      {children}
    </LeadAllocatorContext.Provider>
  );
}

export { LeadAllocatorContext, LeadAllocatorProvider };
