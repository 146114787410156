import React from "react";
import { ISvgProps } from "@components/Svg/interface";

interface ICrossSvgProps extends ISvgProps {
  crossFill?: string;
}

export default function CrossIconSvg({
  width = "25",
  height = "25",
  crossFill = "#909090",
  onClick,
  className,
}: ICrossSvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 19"
      onClick={onClick}
      className={className}
    >
      <path
        fill={crossFill}
        d="M19.33 2.044 17.45.164 9.998 7.617 2.544.164l-1.88 1.88 7.453 7.453-7.453 7.454 1.88 1.88 7.453-7.454 7.454 7.454 1.88-1.88-7.454-7.454z"
      />
    </svg>
  );
}
