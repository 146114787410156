export default {
  fetchFilterModalDataAction: "action/fetchFilterModalData",
  fetchSavedFilterDataSuccess: "action/fetchSavedFilterDataSuccess",
  filterLeadActionDataAvailable: "action/filterLeadActionDataAvailable",
  postFilterLeads: {
    postFilterLeadsDataStart: "action/postFilterLeadsDataStart",
    postFilterLeadsData: "action/postFilterLeadsData",
    postFilterLeadsDataError: "action/postFilterLeadsDataError",
    postFilterLeadsDataSuccess: "action/postFilterLeadsDataSuccess",
    postFilterLeadsDataInitialize: "action/postFilterLeadsDataInitialize",
    postFilterLeadsDataLogId: "action/postFilterLeadsDataLogId",
  },
  toggleOpenFilterModalAction: "action/toggleOpenFilterModal",
  toggleCloseFilterModalAction: "action/toggleCloseFilterModal",
  onExitModal: {
    onExitFilterModal: "action/onExit",
  },
};
