export const LeadStatus = {
  DIRECT_GBS: "DIRECT_GBS",
  QL: "QL",
  UQL: "UQL",
  INV: "INV",
  ABL: "ABL",
  BL: "BL",
  CP: "CP",
  NIBM: "NIBM",
  NIAM: "NIAM",
  MOVING_TO_CX_PLACE: "MOVING_TO_CX_PLACE",
  APPOINTMENT_CONFIRMED: "APPOINTMENT_CONFIRMED",
  CX_MET: "CX_MET",
  SALES_FOLLOWUP: "SALES_FOLLOWUP",
  MAY_TXN: "MAY_TXN",
  MAY_TXN_RNR: "MAY_TXN_RNR",
  MAY_TXN_RESCHEDULED: "MAY_TXN_RESCHEDULED",
  TXN_RAISED: "TXN_RAISED",
  FAILED_TO_SEND: "FAILED_TO_SEND",
  SUCCESS: "SUCCESS",
  VISIT_CANCELLED_ANRC: "VISIT_CANCELLED_ANRC",
  VISIT_CANCELLED_NIBM: "VISIT_CANCELLED_NIBM",
  VISIT_CANCELLED_NIAM: "VISIT_CANCELLED_NIAM",
  VISIT_CONFIRMED: "VISIT_CONFIRMED",
  VISIT_ASSIGNED: "VISIT_ASSIGNED",
  VISIT_IN_PROGRESS: "VISIT_IN_PROGRESS",
  VISIT_RESCHEDULED: "VISIT_RESCHEDULED",
  SALES_RNR: "SALES_RNR",
  VISIT_CANCELLED: "VISIT_CANCELLED",
  VISIT_CANCELLED_IE: "VISIT_CANCELLED_IE",
  VISIT_CANCELLED_GS: "VISIT_CANCELLED_GS",
  VISIT_COMPLETED_BRL: "VISIT_COMPLETED_BRL",
  VISIT_COMPLETED_GL: "VISIT_COMPLETED_GL",
  VISIT_COMPLETED_GBS: "VISIT_COMPLETED_GBS",
  VISIT_CANCELLED_GL: "VISIT_CANCELLED_GL",
  BRL_VISIT: "BRL_VISIT",
  VISIT_CANCELLED_BRL: "VISIT_CANCELLED_BRL",
  QL_WEBSITE: "QL_WEBSITE",
  QL_ORGANIC: "QL_ORGANIC",
  QL_CREF: "QL_CREF",
  QL_SUB: "QL_SUB",
  QL_DIGITAL: "QL_DIGITAL",
  DIRECT_GL: "DIRECT_GL",
  DIRECT_BRL: "DIRECT_BRL",
  DUP: "DUP",
  QL_DC_WALKIN: "QL_DC_WALKIN",
};

export enum ConversionStates {
  CONVERTED = "CONVERTED",
  FRESH_DROP_OFF = "FRESH_DROP_OFF",
  TAKEOVER_DROP_OFF = "TAKEOVER_DROP_OFF",
  NOT_CONVERTED = "NOT_CONVERTED",
  GBS_DROP_OFF = "GBS_DROP_OFF",
}

export enum SalesVisitStatus {
  VISIT_CONFIRMED = "VISIT_CONFIRMED",
  VISIT_ASSIGNED = "VISIT_ASSIGNED",
  VISIT_IN_PROGRESS = "VISIT_IN_PROGRESS",
  SALES_RNR = "SALES_RNR",
  VISIT_COMPLETED_GL = "VISIT_COMPLETED_GL",
  VISIT_COMPLETED_BRL = "VISIT_COMPLETED_BRL",
  VISIT_COMPLETED_GBS = "VISIT_COMPLETED_GBS",
  VISIT_CANCELLED_NIBM = "VISIT_CANCELLED_NIBM",
  VISIT_CANCELLED_GS = "VISIT_CANCELLED_GS",
  VISIT_CANCELLED_NIAM = "VISIT_CANCELLED_NIAM",
  VISIT_CANCELLED_ANRC = "VISIT_CANCELLED_ANRC",
  VISIT_CANCELLED_IE = "VISIT_CANCELLED_IE",
  VISIT_RESCHEDULED = "VISIT_RESCHEDULED",
  VISIT_CANCELLED = "VISIT_CANCELLED",
  MAY_TXN = "MAY_TXN",
  MAY_TXN_RNR = "MAY_TXN_RNR",
  MAY_TXN_RESCHEDULED = "MAY_TXN_RESCHEDULED",
  BRL_VISIT = "BRL_VISIT",
  VISIT_CANCELLED_GL = "VISIT_CANCELLED_GL",
  VISIT_CANCELLED_BRL = "VISIT_CANCELLED_BRL",
}

export interface StatusProps {
  title: string;
  className?: string;
  variant?: string;
}
